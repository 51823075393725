'use strict';

require('bootstrap-sass');

window.myappUtil = {
    lodash: _
};

jQuery(document).ready(function(){
    $.fn.dataTable.ext.errMode = 'throw';
});

window.myappUtil.dataTableDoRowAction = function (dataTable, options) {
    // ensure options is an object
    if (typeof (options) !== 'object' || options === null) {
        options = {};
    }

    if(options['ignoreConfirm'] || confirm(myappUtil.determineOption(options['confirmMessage'], 'Are you sure?'))) {
        jQuery.ajax({
            headers: {
                'X-CSRF-TOKEN' : jQuery('meta[name="csrf-token"]').attr('content')
            },
            url: options.url,
            method: 'POST',
            data: options.data,
            success: function (response, textStatus, jqXHR ) {
                // console.log(response);
                if(response['#status'] === 'success') {
                    myappUtil.showAlert({ status : 'success', message : response['#message'], timeout: 5000});
                    dataTable.ajax.reload(null, false);
                } else {
                    myappUtil.showAlert({ status : 'warning', message : response['#message']});
                }
            },
            error: function(jqXHR, textStatus, errorThrown ) {
                console.error(errorThrown);
                console.error(textStatus);
                myappUtil.showAlert({ status : 'danger', message : '['+errorThrown+'] ' + textStatus});
            }
        });
    }
    return false;
};

window.myappUtil.dataTableDoRowDelete = function (dataTable, options) {
    // ensure options is an object
    if (typeof (options) !== 'object' || options === null) {
        options = {};
    }

    if(confirm(myappUtil.determineOption(options['confirmMessage'], 'The record will be deleted. Are you sure?'))) {
        jQuery.ajax({
            headers: {
                'X-CSRF-TOKEN' : jQuery('meta[name="csrf-token"]').attr('content')
            },
            url: options.url,
            method: 'DELETE',
            success: function (response, textStatus, jqXHR ) {
                // console.log(response);
                if(response['#status'] === 'success') {
                    myappUtil.showAlert({ status : 'success', message : response['#message'], timeout: 5000});
                    dataTable.ajax.reload(null, false);
                } else {
                    myappUtil.showAlert({ status : 'warning', message : response['#message']});
                }
            },
            error: function(jqXHR, textStatus, errorThrown ) {
                console.error(errorThrown);
                console.error(textStatus);
                myappUtil.showAlert({ status : 'danger', message : '['+errorThrown+'] ' + textStatus});
            }
        });
    }
    return false;
};

window.myappUtil.showAlert = function (options) {
    if (typeof (options) === 'object' || options !== null) {

        // default values
        if (!options.target) {
            options.target = {
                selector: '.page-header:first-child', // element select
                position: 'after' // before, after, prepend, append
            }
        }

        if (!options.status) {
            options.status = 'warning';
        }

        var $alertHtml = jQuery('<div class="alert alert-' + options.status + ' alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
          + options.message
          + '</div>');

        var $target = jQuery(options.target.selector);

        if ($target) {
            switch (options.target.position) {
                case 'before':
                    $target.before($alertHtml);
                    break;
                case 'after':
                    $target.after($alertHtml);
                    break;
                case 'prepend':
                    $target.prepend($alertHtml);
                    break;
                case 'append':
                    $target.append($alertHtml);
                    break;
            }
        }

        if (options.timeout > 0) {
            setTimeout(function() {
                $alertHtml.fadeOut( "slow", function() {
                    jQuery(this).remove();
                });
            }, options.timeout);
        }
    }
}

// helper to get option setting or default setting
window.myappUtil.determineOption = function(option, defaultValue) {
    var expectedType = typeof (defaultValue),
        result = defaultValue;

    if (option && typeof (option) === expectedType) {
        result = option;
    }

    return result;
};

window.myappUtil.formatUnixTimestamp = function (ts) {
    var result = '&nbsp;';
    ts = parseInt(ts);
    if (ts > 0) {
        var date = new Date(ts*1000),
        y = date.getFullYear(),
        m = date.getMonth()+1,
        d = date.getDate();

        result = (d > 9 ? d : '0'+d) + '/' + (m > 9 ? m : '0' +m) + '/' + y;
    }

    return result;
};

window.myappUtil.generateQueryString = function (data) {
    var result;

    result = jQuery.param(data);

    return result;
}

// generate absolute url
window.myappUtil.generateUrl = function (uri) {
    var result;

    result = myappProperties.baseUrl + '/' + uri;

    return result;
}

// show or hide part according to the value(s)
// value(s) could be concat via "|"
window.myappUtil.toggleValueRelatedParts = function(valueSelector, relatedParts) {
    var togglePart = function(partSelector, visible) {
            if (visible) {
                $(partSelector).show();
            } else {
                $(partSelector).hide();
            }
        },
        selectedValue = (typeof valueSelector === 'function')
            ? valueSelector() : $(valueSelector).val(),
        realKeys = [];

    // flat the keys
    $.each(relatedParts, function(key) {
        key = key.split('|');
        realKeys = realKeys.concat(key);
    });

    $.each(relatedParts, function(key, partSelectors) {
        key = key.split('|');
        var visible = false;
        if (selectedValue
                && (key.indexOf(selectedValue) > -1
                        || (key.indexOf('#default') > -1
                                && realKeys.indexOf(selectedValue) === -1))) {
            visible = true;
        }
        $.each(partSelectors, function(idx, partSelector) {
            togglePart(partSelector, visible);
        });
    });
};
